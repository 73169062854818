<template>
  <mj-button :href="href" v-bind="buttonStyles">
    <slot />
  </mj-button>
</template>

<script lang="ts" setup>
const props = defineProps<{
  href?: string
  hasIconLeft?: boolean
  hasIconRight?: boolean
  isIconOnly?: boolean
}>()

const innerPadding = computed(() => {
  if (props.hasIconRight) {
    return '9px 10px 9px 20px'
  }

  if (props.hasIconLeft) {
    return '9px 20px 9px 10px'
  }

  if (props.isIconOnly) {
    return '9px 9px 9px 9px'
  }

  return '9px 20px 9px 20px'
})

const twTheme = useTailwindTheme()

const buttonStyles = computed(() => ({
  'border-radius': '21px',
  border: `1px solid ${twTheme.colors['blue-900']}`,
  'background-color': twTheme.colors['blue-900'],
  color: twTheme.colors.white,
  'font-weight': twTheme.fontWeight.bold,
  'font-size': twTheme.fontSize.sm[0],
  'line-height': '21px',
  'inner-padding': innerPadding.value,
}))
</script>
