// @ts-ignore

import colors from '~/tailwind/colors'
import fontSize from '~/tailwind/fontSize'
import fontWeight from '~/tailwind/fontWeight'

export function useTailwindTheme() {
  return {
    colors,
    fontSize,
    fontWeight,
  }
}
