<template>
  <div v-if="text || isEditing" class="email-text">
    <EmailFrameContentSection padding-top="30px" padding-bottom="20px">
      <mj-column width="100%" padding="0">
        <EmailText>
          <div
            v-if="isEditing"
            v-blokkli-editable:paragraphs_text
            v-html="text"
          />

          <div v-else v-html="stylifiedText" />
        </EmailText>
      </mj-column>
    </EmailFrameContentSection>
  </div>
</template>

<script lang="ts" setup>
const { isEditing } = defineBlokkli({
  bundle: 'text',
  editor: {
    editTitle: (el) => el.innerText,
    getDraggableElement: (el) => el.querySelector('.email-text'),
  },
  renderFor: [
    {
      fieldList: 'email',
    },
  ],
})

const props = defineProps<{
  text?: string
}>()

const stylifiedText = computed(() => {
  if (!props.text) {
    return ''
  }

  return props.text
    .replace('<ul', '<ul style="list-style-type: disc; padding-left: 20px;"')
    .replace('<ol', '<ol style="list-style-type: decimal; padding-left: 20px;"')
    .replace(
      '<h3',
      '<h3 style="font-size: 20px; font-weight: bold; margin-top: 13px; margin-bottom: 13px;"',
    )
    .replace('<a', '<a style="color: #1E4557;"')
    .replace('<strong>', '<b style="font-weight: bold;">')
    .replace('</strong>', '</b>')
    .replace('<em>', '<i style="font-style: italic;">')
    .replace('</em>', '</i>')
    .replace('<u>', '<u style="text-decoration: underline;">')
})

defineOptions({
  name: 'ParagraphEmailText',
})
</script>
