<template>
  <div v-if="image && image.image && image.image.file && hash">
    <EmailFrameContentSection :padding-top="paddingTop" padding-bottom="10px">
      <EmailRokkaImage
        :hash="hash"
        :config="imageStyle"
        :file-name="image.image.file.label"
      />
    </EmailFrameContentSection>

    <EmailFrameContentSection padding-top="0" :padding-bottom="paddingBottom">
      <mj-group v-if="image.caption || image.copyright">
        <mj-column width="80%">
          <EmailText v-if="image.caption" size="xs" color="gray-700">
            {{ image.caption }}
          </EmailText>
        </mj-column>
        <mj-column width="20%">
          <EmailText
            v-if="image.copyright"
            size="xs"
            color="gray-300"
            align="right"
          >
            &copy; {{ image.copyright }}
          </EmailText>
        </mj-column>
      </mj-group>
    </EmailFrameContentSection>
  </div>
</template>

<script lang="ts" setup>
import type { MediaImageFragment } from '#graphql-operations'
import type { DefineImageStyleConfigSingle } from '#rokka/types'

const props = defineProps<{
  image?: MediaImageFragment
  format: 'square' | 'wide' | 'original'
  paddingTop?: string
  paddingBottom?: string
}>()

const imageStyleWide = defineImageStyle({
  type: 'single',
  aspectRatio: 1.777777778,
  width: 796,
}) as DefineImageStyleConfigSingle

const imageStyleSquare = defineImageStyle({
  type: 'single',
  aspectRatio: 1,
  width: 796,
}) as DefineImageStyleConfigSingle

const imageStyleOriginal = defineImageStyle({
  type: 'single',
  width: 796,
}) as DefineImageStyleConfigSingle

/**
 * The responsive image style name to use.
 */
const imageStyle = computed<DefineImageStyleConfigSingle>(() => {
  if (props.format === 'square') {
    return imageStyleSquare
  } else if (props.format === 'original') {
    return imageStyleOriginal
  }
  return imageStyleWide
})

const hash = computed(() => props.image?.image?.file?.rokka?.hash)
</script>
