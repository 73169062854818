<template>
  <mj-divider v-bind="dividerStyle" />
</template>

<script lang="ts" setup>
const twTheme = useTailwindTheme()

const dividerStyle = {
  'border-color': twTheme.colors['gray-400'],
  'border-width': '1px',
}
</script>
