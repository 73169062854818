<template>
  <mj-text v-bind="textStyles">
    <!--[if mso | IE]><span style="font-family: Helvetica, Arial, sans-serif, 'Inter' !important"><![endif]-->
    <slot />
    <!--[if mso | IE]></span><![endif]-->
  </mj-text>
</template>

<script lang="ts" setup>
const twTheme = useTailwindTheme()

const props = withDefaults(
  defineProps<{
    size?: keyof typeof twTheme.fontSize
    weight?: keyof typeof twTheme.fontWeight
    color?: keyof typeof twTheme.colors
    align?: 'left' | 'right' | 'center' | 'justify'
  }>(),
  {
    size: 'base',
    weight: 'normal',
    color: 'black',
    align: 'left',
  },
)

const textStyles = computed(() => ({
  'font-family': "'Inter', Helvetica, Arial, sans-serif !important;",
  'font-weight': twTheme.fontWeight[props.weight!],
  'font-size': twTheme.fontSize[props.size!][0],
  'line-height': twTheme.fontSize[props.size!][1],
  padding: '0',
  color: twTheme.colors[props.color!],
  align: props.align,
}))
</script>
