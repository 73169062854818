import type {
  CommerceProductPublicationFragment,
  NodeEventFragment,
  NodeFilterPageFragment,
  NodeGarbageMaterialFragment,
  NodeHelpFragment,
  NodeNewsFragment,
  NodeNewsletterFragment,
  NodeOrganizationPageFragment,
  NodePageFragment,
  NodePressReleaseFragment,
  NodeTopicFragment,
} from '#build/graphql-operations'
import type {
  GraphqlMiddlewareQuery,
  GraphqlMiddlewareResponseUnion,
} from '#build/nuxt-graphql-middleware'
import type { ActiveMenuKey } from './activeMenu'

/**
 * All the possible entity bundles for which a page can be rendered.
 */
type BundleFragmentMap = {
  page: NodePageFragment
  topic: NodeTopicFragment
  organization_page: NodeOrganizationPageFragment
  event: NodeEventFragment
  help: NodeHelpFragment
  news: NodeNewsFragment
  newsletter: NodeNewsletterFragment
  filter_page: NodeFilterPageFragment
  publication: CommerceProductPublicationFragment
  press_release: NodePressReleaseFragment
  garbage_material: NodeGarbageMaterialFragment
}

type ValidBundle = keyof BundleFragmentMap

/**
 * Map the expected entity bundles to the route queries.
 */
const queryTypeMap: Pick<
  Record<keyof GraphqlMiddlewareQuery, ValidBundle[]>,
  | 'route'
  | 'routeNodeHelp'
  | 'routeNodeEvent'
  | 'routeNodeNews'
  | 'routeNodePage'
  | 'routeNodePressRelease'
  | 'routeNodeOrganizationPage'
  | 'routeNodeTopic'
  | 'routeCommerceProduct'
  | 'routeNodeCanonical'
  | 'routeNodeGarbageMaterial'
> = {
  /**
   * The catch-all query, loading data for all possible entity types.
   */
  route: [
    'page',
    'filter_page',
    'organization_page',
    'topic',
    'garbage_material',
  ],
  /**
   * Query that only loads data for the "page" node type.
   */
  routeNodePage: ['page'],
  routeNodeOrganizationPage: ['organization_page'],
  routeNodeTopic: ['topic'],
  routeNodeHelp: ['help'],
  routeNodeEvent: ['event'],
  routeNodeNews: ['news'],
  routeCommerceProduct: ['publication'],
  routeNodePressRelease: ['press_release'],
  routeNodeGarbageMaterial: ['garbage_material'],
  routeNodeCanonical: [
    'event',
    'filter_page',
    'garbage_material',
    'page',
    'news',
    'newsletter',
    'organization_page',
    'topic',
    'press_release',
  ],
} as const

type DrupalRoute = Awaited<ReturnType<typeof useDrupalRoute>>

type TypedEntity<T, E> = {
  bundle: T
  entity: E
}

type AnyNodeTypedEntity<T extends ValidBundle> = {
  bundle: T
  entity: BundleFragmentMap[T]
}

// Determine the expected fragment type for the given bundle.
type EntityType<T extends ValidBundle | 'anyNode' | undefined> =
  T extends ValidBundle
    ? TypedEntity<T, BundleFragmentMap[T]>
    : T extends 'anyNode'
      ? {
          [K in keyof BundleFragmentMap]: AnyNodeTypedEntity<K>
        }[keyof BundleFragmentMap]
      : TypedEntity<
          T,
          | NodePageFragment
          | NodeFilterPageFragment
          | NodeOrganizationPageFragment
          | NodePressReleaseFragment
        >

type UseDrupalRouteQueryResult<T extends ValidBundle | undefined | 'anyNode'> =
  {
    /**
     * The route entity.
     */
    entity: ComputedRef<EntityType<T>['entity']>

    /**
     * The Drupal Route information.
     */
    drupalRoute?: DrupalRoute['drupalRoute']

    /**
     * A narrowable object containing the bundle and the entity.
     */
    typedEntity?: ComputedRef<EntityType<T>>
  }

type UseDrupalRouteQueryOptions = {
  /**
   * Override which active menu key to set.
   */
  menuKey?: ActiveMenuKey
}

function buildLanguageSwitchLinks(
  v?: GraphqlMiddlewareResponseUnion | null,
): Record<string, string> {
  if (v && 'route' in v && v.route && 'languageSwitchLinks' in v.route) {
    return v.route.languageSwitchLinks.reduce<Record<string, string>>(
      (acc, v) => {
        if (v.language.id && v.url.path) {
          acc[v.language.id] = v.url.path
        }
        return acc
      },
      {},
    )
  }

  return {}
}

function getActiveMenuKey(bundle?: ValidBundle): ActiveMenuKey | undefined {
  switch (bundle) {
    case 'topic':
    case 'garbage_material':
      return 'topics'

    case 'organization_page':
    case 'news':
      return 'organization'
  }

  return undefined
}

/**
 * Determines which route query to use.
 */
function getRouteQueryName(
  bundle?: ValidBundle | 'anyNode',
): keyof typeof queryTypeMap {
  switch (bundle) {
    case 'publication':
      return 'routeCommerceProduct'

    case 'topic':
      return 'routeNodeTopic'

    case 'organization_page':
      return 'routeNodeOrganizationPage'

    case 'press_release':
      return 'routeNodePressRelease'

    case 'page':
      return 'routeNodePage'

    case 'news':
      return 'routeNodeNews'

    case 'event':
      return 'routeNodeEvent'

    case 'help':
      return 'routeNodeHelp'

    case 'garbage_material':
      return 'routeNodeGarbageMaterial'

    // Special case 'anyNode' uses a query that contains fragments for all nodes.
    case 'anyNode':
      return 'routeNodeCanonical'
  }

  // Fallback is the route query for entity types whose alias is not known.
  return 'route'
}

/**
 * Handles Drupal routing, metatags, breadcrumbs, language links and menu key.
 */
export async function useDrupalRouteQuery<
  T extends ValidBundle | undefined | 'anyNode',
>(
  /**
   * The bundle to do the route query for.
   *
   * If empty, the fallback route query is used (what only handles some bundles).
   * If 'anyNode', the route query that handles any node bundle is used.
   */
  bundle?: T,

  options?: UseDrupalRouteQueryOptions,
): Promise<UseDrupalRouteQueryResult<T>> {
  const route = useRoute()

  // Workaround for https://github.com/nuxt/nuxt/issues/28804.
  const path = import.meta.client ? window.location.pathname : route.path

  // Determine which route query to use.
  const routeQueryName = getRouteQueryName(bundle)

  // Determine the expected bundles that may be returns for the route query.
  const expectedBundles = queryTypeMap[routeQueryName]

  // Perform the route query.
  const { data: query, error } = await useAsyncGraphqlQuery(
    routeQueryName,
    {
      path,
    },
    {
      transform: (v) => v.data,
      graphqlCaching: {
        client: true,
      },
    },
  )

  // If an error occurs because the frontend cannot connect to the backend,
  // throw a 500 error. If we throw a 404 error, the error page would be
  // cached by fastly and fastly would not serve the previous cached page.
  if (error.value) {
    throw createError({
      statusCode: 500,
      statusMessage: 'Error in Route Query',
      fatal: true,
    })
  }

  const { entity: routeEntity, drupalRoute } = await useDrupalRoute(query.value)

  const entityBundle = computed(
    () => drupalRoute.value.entityBundle as ValidBundle,
  )

  // If the entity bundle we received does not match the expected bundle,
  // throw a 404. Or else a page component expecting a "topic" node would
  // receive a node of another bundle, which would lead to unexpected behaviour.
  if (!entityBundle.value || !expectedBundles.includes(entityBundle.value)) {
    throw createError({
      statusCode: 404,
      fatal: true,
    })
  }

  definePageLanguageLinks(path, buildLanguageSwitchLinks(query.value))
  setActiveMenuKey(options?.menuKey || getActiveMenuKey(entityBundle.value))
  setBreadcrumbLinksFromRoute(query.value)

  const typedEntity = computed(
    () =>
      ({
        bundle: entityBundle.value,
        entity: routeEntity.value,
      }) as EntityType<T>,
  )

  return {
    entity: routeEntity as ComputedRef<EntityType<T>['entity']>,
    drupalRoute,
    typedEntity,
  }
}
