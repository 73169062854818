<template>
  <mj-table padding="0">
    <tbody>
      <tr>
        <td
          :style="`border-top: 1px solid ${twTheme.colors['gray-400']}; padding: 0;`"
        >
          <mj-wrapper full-width="full-width" padding="0">
            <slot />
          </mj-wrapper>
        </td>
      </tr>
    </tbody>
  </mj-table>
</template>

<script lang="ts" setup>
const twTheme = useTailwindTheme()
</script>
