<template>
  <div v-if="hash" class="email-image">
    <EmailImage :image="image" :format="options.format" />
  </div>
</template>

<script lang="ts" setup>
import type { MediaImageFragment } from '#graphql-operations'

const { options } = defineBlokkli({
  bundle: 'image',
  options: {
    format: {
      type: 'radios',
      label: 'Bildformat',
      default: 'wide',
      options: {
        square: 'Quadratisch',
        wide: 'Quer',
        original: 'Original',
      },
    },
  },
  editor: {
    editTitle: (el) => {
      const img = el.querySelector('img')
      return img?.alt || img?.title
    },
    getDraggableElement: (el) => el.querySelector('.email-image'),
  },
  renderFor: [
    {
      fieldList: 'email',
    },
  ],
})

const props = defineProps<{
  image?: MediaImageFragment
}>()

const hash = computed(() => props.image?.image?.file?.rokka?.hash)
</script>
