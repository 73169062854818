<template>
  <mjml :used-fonts="['Inter']">
    <mj-body>
      <slot />
    </mj-body>
  </mjml>
</template>

<script lang="ts" setup>
useHead({
  style: [
    {
      innerHTML: `
ul {
  list-style-type: disc;
  padding-left: 20px;
}
ol {
  list-style-type: decimal;
  padding-left: 20px;
}
h3 {
  font-size: 20px;
  font-weight: bold;
  margin-top: 13px;
  margin-bottom: 13px;
}
p > a {
  color: #1E4557;
}
      `,
    },
  ],
})
</script>
