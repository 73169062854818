<template>
  <div :class="{ 'py-15 -my-15': isEditing }">
    <EmailDivider />
  </div>
</template>

<script lang="ts" setup>
/**
 * This paragraph needs a padding bottom so that is can be selected in blökkli.
 * To compensate, it adds a negative margin at the bottom as well so that the
 * spacing remains consistent.
 */
const { isEditing } = defineBlokkli({
  bundle: 'horizontal_line',
  editor: {
    disableEdit: true,
    addBehaviour: 'no-form',
  },
  renderFor: [
    {
      fieldList: 'email',
    },
  ],
})
</script>
