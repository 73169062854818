<template>
  <div v-if="title || isEditing" :id="id">
    <EmailFrameContentSection padding-top="30px" padding-bottom="20px">
      <mj-column width="100%" padding="0">
        <EmailTitleSection>
          <span
            v-if="isEditing"
            v-blokkli-editable:field_title="{ required: true }"
            class="inline-block"
          >
            {{ title }}
          </span>

          <template v-else>
            {{ title }}
          </template>
        </EmailTitleSection>
      </mj-column>
    </EmailFrameContentSection>
  </div>
</template>

<script lang="ts" setup>
import { slugify } from '~/helpers/slugify'

const { isEditing } = defineBlokkli({
  bundle: 'section_title',
  editor: {
    editTitle: (el) => el.innerText,
    getDraggableElement: (el) => el.querySelector('span'),
  },
  renderFor: [
    {
      fieldList: 'email',
    },
  ],
})

const props = defineProps<{
  title: string
  anchor?: string
}>()

const id = computed(() => slugify(props.title))
</script>
