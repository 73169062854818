<template>
  <mj-button :href="href" v-bind="buttonStyles">
    <slot />
  </mj-button>
</template>

<script lang="ts" setup>
const props = defineProps<{
  href?: string
  hasIconLeft?: boolean
  hasIconRight?: boolean
  isIconOnly?: boolean
}>()

const twTheme = useTailwindTheme()

const innerPadding = computed(() => {
  if (props.hasIconRight) {
    return '4px 4px 4px 20px'
  }

  if (props.hasIconLeft) {
    return '4px 20px 4px 4px'
  }

  if (props.isIconOnly) {
    return '4px 4px 4px 4px'
  }

  return '4px 20px 4px 20px'
})

const buttonStyles = computed(() => ({
  'border-radius': '21px',
  border: `1px solid ${twTheme.colors['blue-900']}`,
  'background-color': twTheme.colors['gray-20'],
  color: twTheme.colors['blue-900'],
  'font-weight': twTheme.fontWeight.bold,
  'font-size': twTheme.fontSize.sm[0],
  'line-height': '32px',
  'inner-padding': innerPadding.value,
}))
</script>
