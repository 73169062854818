<template>
  <div>
    <EmailTeaser
      :title-above-image="titleAboveImage"
      :image="media"
      :title="title"
      :text="text"
      :href="teaserUrl"
    />
  </div>
</template>

<script lang="ts" setup>
/**
 * This paragraph has a link field that allowed both internal and external links.
 * In case of external links, the title, text and media fields are required. For
 * internal links, they are optional.
 *
 * The title, text and media fields should always be used as the primary source.
 * If the link is internal we can fall back to the linked entity's teaser data.
 */
import type {
  MediaImageFragment,
  ParagraphTeaserEntityFragment,
  ParagraphTeaserFragment,
} from '#graphql-operations'

defineBlokkli({
  bundle: 'teaser',
  editor: {
    editTitle: (el) => el.querySelector('h3')?.innerText,
    mockProps: (text) => {
      return {
        overrideTitle: text || 'Teaser Titel',
        link: {
          uri: {
            path: '/',
          },
        },
      }
    },
  },
  renderFor: [
    {
      fieldList: 'email',
    },
  ],
})

const props = defineProps<{
  link?: ParagraphTeaserFragment['link']
  overrideTitle?: ParagraphTeaserFragment['overrideTitle']
  overrideText?: ParagraphTeaserFragment['overrideText']
  overrideMedia?: ParagraphTeaserFragment['overrideMedia']
  titleAboveImage?: ParagraphTeaserFragment['titleAboveImage']
}>()

/**
 * If the link is internal, this contains the entity that is being linked.
 */
const entity = computed<ParagraphTeaserEntityFragment | undefined>(() => {
  const url = props.link?.uri
  if (url && 'entity' in url) {
    return url.entity
  }
  return undefined
})

const title = computed(() => {
  if (props.overrideTitle) return props.overrideTitle
  if (entity.value && 'teaserTitle' in entity.value) {
    return entity.value.teaserTitle
  }
  return ''
})

const text = computed(() => {
  if (props.overrideText) return props.overrideText
  if (entity.value && 'teaserText' in entity.value) {
    return entity.value.teaserText
  }
  return ''
})

const media = computed<MediaImageFragment | undefined>(() => {
  if (props.overrideMedia) return props.overrideMedia
  if (entity.value && 'teaserImage' in entity.value) {
    return entity.value.teaserImage
  }
  return undefined
})

const teaserUrl = computed(() => {
  const path = props.link?.uri?.path

  if (!path) {
    return ''
  }

  return path
})
</script>
