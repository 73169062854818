<template>
  <mj-image v-if="src" :src="src" padding="0" />
</template>

<script lang="ts" setup>
import {
  type BuildRokkaUrlVariables,
  type DefineImageStyleConfigSingle,
} from '#rokka/types'
import { useRuntimeConfig, buildRokkaUrl, computed } from '#imports'

const runtimeConfig = useRuntimeConfig()

const props = defineProps<{
  /**
   * The rokka hash.
   */
  hash: string

  /**
   * Configure what and how the image should be rendered.
   *
   * If a string is provided it's assumed to be an existing rokka stack.
   * In this case a simple <img> tag is rendered.
   */
  config: DefineImageStyleConfigSingle | string

  /**
   * The name of the file used for building the URL.
   *
   * If no file name is provided, the URL is generated without a file name.
   */
  fileName?: string

  /**
   * Override the host for a specific image. Falls back to the host defined in the module configuration.
   */
  host?: string
}>()

const src = computed(() => {
  if (typeof props.config === 'string') {
    return buildRokkaUrl(
      props.config,
      '',
      props.hash,
      props.fileName,
      props.host,
    )
  }

  const variables: BuildRokkaUrlVariables = {
    w: props.config.width,
  }
  let height = props.config.height || 0
  let stack =
    props.config.stacks?.noCrop || runtimeConfig.public.rokkaStackNoCrop

  if (props.config.aspectRatio) {
    height = Math.round(props.config.width / props.config.aspectRatio)
  }

  if (height) {
    stack = props.config.stacks?.crop || runtimeConfig.public.rokkaStackCrop
    variables.h = height
  }

  return buildRokkaUrl(
    stack,
    '',
    props.hash,
    props.fileName,
    props.host,
    variables,
  )
})
</script>
