<template>
  <mj-section v-bind="contentSectionStyle">
    <slot />
  </mj-section>
</template>

<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    paddingTop?: string
    paddingBottom?: string
    backgroundColor?: string
  }>(),
  {
    paddingTop: '40px',
    paddingBottom: '40px',
    backgroundColor: '#ffffff',
  },
)

const contentSectionStyle = computed(() => ({
  'padding-top': props.paddingTop,
  'padding-bottom': props.paddingBottom,
  'padding-left': '16px',
  'padding-right': '16px',
  'background-color': props.backgroundColor,
}))
</script>
