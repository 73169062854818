<template>
  <div>
    <EmailFrameContentSection padding-top="30px" padding-bottom="20px">
      <EmailButtonPrimary v-if="options.type === 'primary'" :href="buttonUrl">
        {{ buttonTitle }}
      </EmailButtonPrimary>
      <EmailButton v-else :href="buttonUrl">
        {{ buttonTitle }}
      </EmailButton>
    </EmailFrameContentSection>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphButtonFragment } from '#graphql-operations'

const { options } = defineBlokkli({
  bundle: 'button',
  options: {
    type: {
      type: 'radios',
      label: 'Darstellung',
      default: 'primary',
      options: {
        primary: 'Primär',
        secondary: 'Sekundär',
      },
    },
  },
  editor: {
    editTitle: (el) => el.querySelector('a')?.innerText,
    mockProps: (text) => {
      return {
        title: text || 'Titel vom Button',
        link: {
          uri: {
            entityPath: '/',
          },
        },
      }
    },
    getDraggableElement: (el) => el.querySelector('a'),
  },
  renderFor: [
    {
      fieldList: 'email',
    },
  ],
})

const props = defineProps<{
  title?: ParagraphButtonFragment['title']
  link: ParagraphButtonFragment['link']
}>()

const linkedEntity = computed(() => {
  if (props.link?.uri && 'entity' in props.link.uri) {
    return props.link.uri.entity
  }
  return undefined
})

const buttonUrl = computed(() => {
  // If entity is MediaDocument.
  if (linkedEntity?.value?.__typename === 'MediaDocument') {
    // Use originalFileUrl for documents.
    if (
      'originalFileUrl' in linkedEntity.value &&
      linkedEntity.value.originalFileUrl
    ) {
      return linkedEntity.value.originalFileUrl
    }

    // If URL is given.
    if (linkedEntity.value.media?.entity?.uriRawField?.first?.url) {
      return linkedEntity.value.media?.entity?.uriRawField?.first?.url
    }
  }

  return props.link?.uri?.entityPath
})

const buttonTitle = computed(() => {
  if (props.title) {
    return props.title
  }

  if (linkedEntity.value && 'teaserTitle' in linkedEntity.value) {
    return linkedEntity.value.teaserTitle
  } else if (linkedEntity.value && 'name' in linkedEntity.value) {
    return linkedEntity.value.name
  }

  return ''
})
</script>
