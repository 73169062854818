const fontSize: Record<string, [string, string]> = {
  '9xl': ['128px', '128px'],
  '8xl': ['96px', '96px'],
  '7xl': ['72px', '72px'],
  '6xl': ['60px', '60px'],
  '5xl': ['48px', '48px'],
  '4xl': ['36px', '40px'],
  '3xl': ['30px', '34px'],
  '2xl': ['24px', '32px'],
  xl: ['20px', '28px'],
  lg: ['18px', '24px'],
  base: ['16px', '22px'],
  sm: ['14px', '20px'],
  xs: ['12px', '18px'],
}

export default fontSize
